import { version } from "./version";
import { config } from "./config";

export const environment = {
  production: true,
  name: 'staging',
  version: version,
  debug: true,
  api: 'https://api.coolgreet.de/api/v2',
  redirectUri: 'http://localhost/callback',
  assets: config.assets,
  pwa: config.pwa,
  prodPwa: config.prodPwa,
  deeplink: config.deeplink,
  appStoreLink: config.appStoreLink,
  playStoreLink: config.playStoreLink,
  benefitUrl: config.benefitUrl,
  availableLangs: config.availableLangs,
  liveTranslation: config.liveTranslation,
  cookieForAssets: true,
  hideSidebarPublicPages: config.hideSidebarPublicPages,
  publicPagesLang: config.publicPagesLang,
  hideLogout: config.hideLogout,
  hideSettingsPasswordReset: config.hideSettingsPasswordReset,
  atsAnonymous: config.atsAnonymous,
  noIframeRediret: config.noIframeRediret,
  showInstallPrompt: config.showInstallPrompt,
  showCredentialWallet: config.showCredentialWallet,
  hideRatingInMembers: config.hideRatingInMembers,
  sameSiteAssets: config.sameSiteAssets,
  useLicenceTotal: [610],
  google: {
      apiKeys: {
          android: 'AIzaSyArTn4ZZsimCyTU4U776Ww1mxGicQwUEG4',
          ios: 'AIzaSyDhrhGJ_ft5cAKP4_ST_QK7L5Lk1JvwsCc',
          web: 'AIzaSyBagu5q2R6i-IvZ8y6xDT3tfDJNwOeb9ow'
      },
      apiKey: 'AIzaSyArTn4ZZsimCyTU4U776Ww1mxGicQwUEG4'
  },
  linkedIn: {
      clientId: config.linkedIn.clientId
  },
  mobileAppId: config.mobileAppId,
  sentry: {
      enabled: true,
      dialog: true,
      dsn: 'https://3f99218da0aa4b1ab32e13029b3b8895@sentry.congreet.com/1'
  },
  customDomains: [
    window.location.host,
    'app.skillsclub.com',
    // tb. and tqb. prefix will be probably used mostly by testing boards, so we will conver those in this case also
    'tqb.skillsclub.com',
    'tb.skillsclub.com',
    'pwa.congreet.com',
    '.coolgreet.de',
  ],
  customScheme: config.customScheme,
  wizard_skip_ttl: 300,
  countryDropdown: config.countryDropdown,
  // key is domain + values is list of community and specific tracking ID, 0 - default trakcing ID
  trackingWhitelist: config.trackingWhitelist,
  limitedMemberList: config.limitedMemberList,
  hostname: location.hostname.includes('localhost') ? config.assets.replace(/(http:\/\/|https:\/\/)/, '') : location.hostname
};
